<template>
    <div>
        <div class="nfs-create-issue">
            <NuxtLinkLocale to="/issue-form-new">
                <button
                    v-if="canCreateIssue"
                    class="rounded-full bg-brand-300 p-3 shadow-sm hover:bg-brand-400"
                >
                    <NfsIcon
                        icon="icon-plus"
                        size="medium"
                    />
                </button>
                <button
                    v-else
                    v-tooltip="$t('TOOLTIP_MESSAGE.LOGIN_TO_CREATE_ISSUE')"
                    class="rounded-full bg-brand-300 p-3 opacity-50 shadow-sm"
                    disabled="true"
                >
                    <NfsIcon
                        icon="icon-plus"
                        size="medium"
                    />
                </button>
            </NuxtLinkLocale>
        </div>
        <slot />
    </div>
</template>

<script setup lang="ts">
import { isUserPermitted } from '../composables/userPermissions';
import { UserPermissionType } from '../../shared/shared.interface';

const { layer2 } = useRuntimeConfig().public.appLayout.zIndex;
const canCreateIssue = ref(false);
canCreateIssue.value = await isUserPermitted(UserPermissionType.CREATE_ISSUE);

watchEffect(async() => {
    if (useIsAuthenticated())
        canCreateIssue.value = await isUserPermitted(UserPermissionType.CREATE_ISSUE);
});
</script>

<style scoped>
.nfs-create-issue {
 @apply fixed bottom-8 right-1 z-[v-bind(layer2)]
}
</style>
