import { default as permissions2P43Zi1u8kMeta } from "/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/admin/permissions.vue?macro=true";
import { default as permissions2DPe1T0v5mMeta } from "/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/admin/role/permissions.vue?macro=true";
import { default as roleswMqiskakJmMeta } from "/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/admin/roles.vue?macro=true";
import { default as userinvitesR9qHNqiwSMMeta } from "/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/admin/userinvites.vue?macro=true";
import { default as usersAmXsWkx6a1Meta } from "/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/admin/users.vue?macro=true";
import { default as appsettingslY4HDn1sCGMeta } from "/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/appsettings.vue?macro=true";
import { default as categoriesNfrpfwSi0uMeta } from "/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/categories.vue?macro=true";
import { default as custom_45fields_91_91id_93_93Od2OtTqeElMeta } from "/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/custom-fields[[id]].vue?macro=true";
import { default as index7VedjHt42GMeta } from "/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/index.vue?macro=true";
import { default as issue_45form_45new_91_91id_93_93nz4Xes44YoMeta } from "/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/issue-form-new[[id]].vue?macro=true";
import { default as issue_45form_91_91id_93_93q9mrZpWTlRMeta } from "/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/issue-form[[id]].vue?macro=true";
import { default as details_45_91id_938zaJL4RdL0Meta } from "/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/issue/details-[id].vue?macro=true";
import { default as issuesa2wSH6FP6vMeta } from "/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/issues.vue?macro=true";
import { default as login9NZBQ6oec4Meta } from "/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/login.vue?macro=true";
import { default as notificationSettings5Hd328Mf0RMeta } from "/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/notificationSettings.vue?macro=true";
export default [
  {
    name: permissions2P43Zi1u8kMeta?.name ?? "admin-permissions",
    path: permissions2P43Zi1u8kMeta?.path ?? "/admin/permissions",
    meta: permissions2P43Zi1u8kMeta || {},
    alias: permissions2P43Zi1u8kMeta?.alias || [],
    redirect: permissions2P43Zi1u8kMeta?.redirect,
    component: () => import("/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/admin/permissions.vue").then(m => m.default || m)
  },
  {
    name: permissions2DPe1T0v5mMeta?.name ?? "admin-role-permissions",
    path: permissions2DPe1T0v5mMeta?.path ?? "/admin/role/permissions",
    meta: permissions2DPe1T0v5mMeta || {},
    alias: permissions2DPe1T0v5mMeta?.alias || [],
    redirect: permissions2DPe1T0v5mMeta?.redirect,
    component: () => import("/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/admin/role/permissions.vue").then(m => m.default || m)
  },
  {
    name: roleswMqiskakJmMeta?.name ?? "admin-roles",
    path: roleswMqiskakJmMeta?.path ?? "/admin/roles",
    meta: roleswMqiskakJmMeta || {},
    alias: roleswMqiskakJmMeta?.alias || [],
    redirect: roleswMqiskakJmMeta?.redirect,
    component: () => import("/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/admin/roles.vue").then(m => m.default || m)
  },
  {
    name: userinvitesR9qHNqiwSMMeta?.name ?? "admin-userinvites",
    path: userinvitesR9qHNqiwSMMeta?.path ?? "/admin/userinvites",
    meta: userinvitesR9qHNqiwSMMeta || {},
    alias: userinvitesR9qHNqiwSMMeta?.alias || [],
    redirect: userinvitesR9qHNqiwSMMeta?.redirect,
    component: () => import("/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/admin/userinvites.vue").then(m => m.default || m)
  },
  {
    name: usersAmXsWkx6a1Meta?.name ?? "admin-users",
    path: usersAmXsWkx6a1Meta?.path ?? "/admin/users",
    meta: usersAmXsWkx6a1Meta || {},
    alias: usersAmXsWkx6a1Meta?.alias || [],
    redirect: usersAmXsWkx6a1Meta?.redirect,
    component: () => import("/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    name: appsettingslY4HDn1sCGMeta?.name ?? "appsettings",
    path: appsettingslY4HDn1sCGMeta?.path ?? "/appsettings",
    meta: appsettingslY4HDn1sCGMeta || {},
    alias: appsettingslY4HDn1sCGMeta?.alias || [],
    redirect: appsettingslY4HDn1sCGMeta?.redirect,
    component: () => import("/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/appsettings.vue").then(m => m.default || m)
  },
  {
    name: categoriesNfrpfwSi0uMeta?.name ?? "categories",
    path: categoriesNfrpfwSi0uMeta?.path ?? "/categories",
    meta: categoriesNfrpfwSi0uMeta || {},
    alias: categoriesNfrpfwSi0uMeta?.alias || [],
    redirect: categoriesNfrpfwSi0uMeta?.redirect,
    component: () => import("/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/categories.vue").then(m => m.default || m)
  },
  {
    name: custom_45fields_91_91id_93_93Od2OtTqeElMeta?.name ?? "custom-fieldsid",
    path: custom_45fields_91_91id_93_93Od2OtTqeElMeta?.path ?? "/custom-fields:id?",
    meta: custom_45fields_91_91id_93_93Od2OtTqeElMeta || {},
    alias: custom_45fields_91_91id_93_93Od2OtTqeElMeta?.alias || [],
    redirect: custom_45fields_91_91id_93_93Od2OtTqeElMeta?.redirect,
    component: () => import("/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/custom-fields[[id]].vue").then(m => m.default || m)
  },
  {
    name: index7VedjHt42GMeta?.name ?? "index",
    path: index7VedjHt42GMeta?.path ?? "/",
    meta: index7VedjHt42GMeta || {},
    alias: index7VedjHt42GMeta?.alias || [],
    redirect: index7VedjHt42GMeta?.redirect,
    component: () => import("/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: issue_45form_45new_91_91id_93_93nz4Xes44YoMeta?.name ?? "issue-form-newid",
    path: issue_45form_45new_91_91id_93_93nz4Xes44YoMeta?.path ?? "/issue-form-new:id?",
    meta: issue_45form_45new_91_91id_93_93nz4Xes44YoMeta || {},
    alias: issue_45form_45new_91_91id_93_93nz4Xes44YoMeta?.alias || [],
    redirect: issue_45form_45new_91_91id_93_93nz4Xes44YoMeta?.redirect,
    component: () => import("/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/issue-form-new[[id]].vue").then(m => m.default || m)
  },
  {
    name: issue_45form_91_91id_93_93q9mrZpWTlRMeta?.name ?? "issue-formid",
    path: issue_45form_91_91id_93_93q9mrZpWTlRMeta?.path ?? "/issue-form:id?",
    meta: issue_45form_91_91id_93_93q9mrZpWTlRMeta || {},
    alias: issue_45form_91_91id_93_93q9mrZpWTlRMeta?.alias || [],
    redirect: issue_45form_91_91id_93_93q9mrZpWTlRMeta?.redirect,
    component: () => import("/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/issue-form[[id]].vue").then(m => m.default || m)
  },
  {
    name: details_45_91id_938zaJL4RdL0Meta?.name ?? "issue-details-id",
    path: details_45_91id_938zaJL4RdL0Meta?.path ?? "/issue/details-:id()",
    meta: details_45_91id_938zaJL4RdL0Meta || {},
    alias: details_45_91id_938zaJL4RdL0Meta?.alias || [],
    redirect: details_45_91id_938zaJL4RdL0Meta?.redirect,
    component: () => import("/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/issue/details-[id].vue").then(m => m.default || m)
  },
  {
    name: issuesa2wSH6FP6vMeta?.name ?? "issues",
    path: issuesa2wSH6FP6vMeta?.path ?? "/issues",
    meta: issuesa2wSH6FP6vMeta || {},
    alias: issuesa2wSH6FP6vMeta?.alias || [],
    redirect: issuesa2wSH6FP6vMeta?.redirect,
    component: () => import("/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/issues.vue").then(m => m.default || m)
  },
  {
    name: login9NZBQ6oec4Meta?.name ?? "login",
    path: login9NZBQ6oec4Meta?.path ?? "/login",
    meta: login9NZBQ6oec4Meta || {},
    alias: login9NZBQ6oec4Meta?.alias || [],
    redirect: login9NZBQ6oec4Meta?.redirect,
    component: () => import("/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: notificationSettings5Hd328Mf0RMeta?.name ?? "notificationSettings",
    path: notificationSettings5Hd328Mf0RMeta?.path ?? "/notificationSettings",
    meta: notificationSettings5Hd328Mf0RMeta || {},
    alias: notificationSettings5Hd328Mf0RMeta?.alias || [],
    redirect: notificationSettings5Hd328Mf0RMeta?.redirect,
    component: () => import("/home/musman1/workspace/FixSirat-Admin/fixsirat-admin-frontend/pages/notificationSettings.vue").then(m => m.default || m)
  }
]