import { defineNuxtPlugin } from '#app/nuxt'
import { LazyActionPanel, LazyAnimatedModal, LazyAudioComponent, LazyBreadCrumb, LazyCameraComponent, LazyCapture, LazyCommandPalette, LazyCommentsComponent, LazyComparisonSlider, LazyDividerwithButton, LazyDrawerPanel, LazyFilter, LazyFormFieldLabel, LazyFormImagesSection, LazyInlineAlert, LazyIssueForm, LazyIssueThumbnail, LazyLanguageSwitcher, LazyListComponent, LazyLoadSpinner, LazyLoginForm, LazyMapComponent, LazyMenuDropDown, LazyModalComponent, LazyNfsAlert, LazyNfsButton, LazyNfsCard, LazyNfsEmptyState, LazyNfsFileUploader, LazyNfsHeader, LazyNfsIcon, LazyNfsInput, LazyNfsSelect, LazyNfsSidebar, LazyNfsTable, LazyOfflineAlert, LazyRadialProgress, LazySectionHeader, LazyTimeline, LazyUserWidget, LazyStats, LazySvgoAt, LazySvgoCalendar, LazySvgoCamera, LazySvgoCategory, LazySvgoComplexity, LazySvgoDelete, LazySvgoExpandDown, LazySvgoFileUpload, LazySvgoFilter, LazySvgoGlobe, LazySvgoGrid, LazySvgoHambergerMenu, LazySvgoHomeIcon, LazySvgoHome, LazySvgoIconBack, LazySvgoIconCancel, LazySvgoIconCross, LazySvgoIconEdit, LazySvgoIconExpandDown, LazySvgoIconExpandUp, LazySvgoIconMic, LazySvgoIconPlay, LazySvgoIconPlus, LazySvgoIconSend, LazySvgoIconStop, LazySvgoLogo, LazySvgoMoney, LazySvgoNotifications, LazySvgoPermissions, LazySvgoPhoto, LazySvgoPlusSmall, LazySvgoRoadPin, LazySvgoSetting, LazySvgoUserCircled, LazySvgoUserInvites, LazySvgoUser, LazySvgoUsersGroup, LazySvgoUsersManagement, LazySvgoUsersRoles, LazySvgoUsers } from '#components'
const lazyGlobalComponents = [
  ["ActionPanel", LazyActionPanel],
["AnimatedModal", LazyAnimatedModal],
["AudioComponent", LazyAudioComponent],
["BreadCrumb", LazyBreadCrumb],
["CameraComponent", LazyCameraComponent],
["Capture", LazyCapture],
["CommandPalette", LazyCommandPalette],
["CommentsComponent", LazyCommentsComponent],
["ComparisonSlider", LazyComparisonSlider],
["DividerwithButton", LazyDividerwithButton],
["DrawerPanel", LazyDrawerPanel],
["Filter", LazyFilter],
["FormFieldLabel", LazyFormFieldLabel],
["FormImagesSection", LazyFormImagesSection],
["InlineAlert", LazyInlineAlert],
["IssueForm", LazyIssueForm],
["IssueThumbnail", LazyIssueThumbnail],
["LanguageSwitcher", LazyLanguageSwitcher],
["ListComponent", LazyListComponent],
["LoadSpinner", LazyLoadSpinner],
["LoginForm", LazyLoginForm],
["MapComponent", LazyMapComponent],
["MenuDropDown", LazyMenuDropDown],
["ModalComponent", LazyModalComponent],
["NfsAlert", LazyNfsAlert],
["NfsButton", LazyNfsButton],
["NfsCard", LazyNfsCard],
["NfsEmptyState", LazyNfsEmptyState],
["NfsFileUploader", LazyNfsFileUploader],
["NfsHeader", LazyNfsHeader],
["NfsIcon", LazyNfsIcon],
["NfsInput", LazyNfsInput],
["NfsSelect", LazyNfsSelect],
["NfsSidebar", LazyNfsSidebar],
["NfsTable", LazyNfsTable],
["OfflineAlert", LazyOfflineAlert],
["RadialProgress", LazyRadialProgress],
["SectionHeader", LazySectionHeader],
["Timeline", LazyTimeline],
["UserWidget", LazyUserWidget],
["Stats", LazyStats],
["SvgoAt", LazySvgoAt],
["SvgoCalendar", LazySvgoCalendar],
["SvgoCamera", LazySvgoCamera],
["SvgoCategory", LazySvgoCategory],
["SvgoComplexity", LazySvgoComplexity],
["SvgoDelete", LazySvgoDelete],
["SvgoExpandDown", LazySvgoExpandDown],
["SvgoFileUpload", LazySvgoFileUpload],
["SvgoFilter", LazySvgoFilter],
["SvgoGlobe", LazySvgoGlobe],
["SvgoGrid", LazySvgoGrid],
["SvgoHambergerMenu", LazySvgoHambergerMenu],
["SvgoHomeIcon", LazySvgoHomeIcon],
["SvgoHome", LazySvgoHome],
["SvgoIconBack", LazySvgoIconBack],
["SvgoIconCancel", LazySvgoIconCancel],
["SvgoIconCross", LazySvgoIconCross],
["SvgoIconEdit", LazySvgoIconEdit],
["SvgoIconExpandDown", LazySvgoIconExpandDown],
["SvgoIconExpandUp", LazySvgoIconExpandUp],
["SvgoIconMic", LazySvgoIconMic],
["SvgoIconPlay", LazySvgoIconPlay],
["SvgoIconPlus", LazySvgoIconPlus],
["SvgoIconSend", LazySvgoIconSend],
["SvgoIconStop", LazySvgoIconStop],
["SvgoLogo", LazySvgoLogo],
["SvgoMoney", LazySvgoMoney],
["SvgoNotifications", LazySvgoNotifications],
["SvgoPermissions", LazySvgoPermissions],
["SvgoPhoto", LazySvgoPhoto],
["SvgoPlusSmall", LazySvgoPlusSmall],
["SvgoRoadPin", LazySvgoRoadPin],
["SvgoSetting", LazySvgoSetting],
["SvgoUserCircled", LazySvgoUserCircled],
["SvgoUserInvites", LazySvgoUserInvites],
["SvgoUser", LazySvgoUser],
["SvgoUsersGroup", LazySvgoUsersGroup],
["SvgoUsersManagement", LazySvgoUsersManagement],
["SvgoUsersRoles", LazySvgoUsersRoles],
["SvgoUsers", LazySvgoUsers],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
